import styled from "styled-components"

export const BasicTextImageModuleStyles = styled.section`
   .strong-text {
      font-weight: 700;
    }

  .container {

    .grid {
      display: grid;
      grid-template-columns: 30% 1fr;
      grid-template-rows: auto;
      grid-column: 1;
      grid-template-areas: 
          "imageA imageA"
          "contentA contentA"
          "imageB contentB"
          "contentB_p contentB_p";
        

        @media (min-width: 768px) {
          grid-template-columns: 1fr 5% 1fr;
          grid-template-areas: 
          "imageA . imageB"
          "contentA . contentB";
        }
    }

    .gridImageA{
      grid-area: imageA;
    }
    .gridImageB{
      grid-area: imageB;
    }
    .gridContentA{
      grid-area: contentA;
      margin-bottom: 60px;

      @media (min-width: 768px) {
        margin-bottom: 0;
      }

      .titolo {
        font-size: var(--h2);
        color: #70bf73;
      }
    }
    
    .gridContentB{
      grid-area: contentB;
      display:none;
      @media (min-width: 768px) {
        display:block;
      }

      h2, p {
        text-align:right;
      }
      .titolo {
        font-size: var(--h2);
        color: #70bf73;
        text-align:right;
      }
    }

    .gridContentB_titolo_mobile {
      grid-area: contentB;
      @media (min-width: 768px) {
        display:none
      }
      h2, p {
        text-align:left;
      }
      .titolo {
        font-size: var(--h2);
        color: #70bf73;
        text-align:left;
        margin-left:10px;

      }
    }

    .gridContentB_p_mobile {
      grid-area: contentB_p;
      @media (min-width: 768px) {
        display:none
      }
      h2, p {
        text-align:left;
        margin-top:0;
      }

      .holder-btn {
        margin-top:30px;
        @media (min-width: 768px) {
          margin-top:0;
        }
      }
    }

    

    h2, p {
      color:var(--primary);
    }
    
    
    .holder-trattore {
      max-width: 80%;
      /* background: #fff; */
      text-align:right;
      margin-left: 0;
      margin-right: auto;
    }

    .holder-btn {
      text-align:center;
    }

    .olio_image {
      max-width: 100%;
      border-radius: 10px;
     /*  filter: drop-shadow(2px 0px 2px #333 ); */
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      @media (min-width: 768px) {
        max-width: 40%;
      }

      & > * {
        flex-grow: 1;
      }
    }

    display: flex;
    @media (min-width: 768px) {
      > div {
       /*  width: 66.666%;
        max-width: 700px; */
      }
    }
  }
`
