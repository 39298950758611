import styled from "styled-components"

export const BannerModuleStyles = styled.section`
  height: 100vh;
  position: relative;
  padding: 30px 0;
  filter:drop-shadow(1px 5px 5px rgba(0,0,0,0.3));
 
  h1,
  h2, {
    text-shadow: var(--textShadow);
  }
  
  
  .container {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

   
  }

  .gradient,
  .banner__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .gradient {
    background: radial-gradient(
      at bottom left,
      rgba(174, 174, 174, 0.5),
      rgba(112, 186, 144, 0)
    );
  }

  .banner__content {
    position: relative;
    z-index: 2;
    min-height: 33vh;
    width: 100%;
    max-width: 700px;
    padding-top: 30px;
    display:flex;
    align-items: center;
    min-width:100%;

    .sx {
      padding: 0 var(--borderSpacing);
      width:90%;
      max-height: 86vh;

      .logo_banner {
        width: 300px;
        text-align:left;
        margin-right:10px;

        svg {
          filter: drop-shadow(1px 5px 5px rgba(0, 0, 0, 0.5));
          margin-left:-10px;
        }

        .welcomeText {
          font-size: var(--h6);
          font-weight: 600;
          color:#70bf73;
          margin-left:10px;
        }
      }
    }
   

    @media (min-width: 768px) {
      padding-top: 0px;
      width: 100vw;
    }

   
    h1,
    h2 {
      text-shadow: var(--textShadow);
    }

    h1 {
       margin-bottom: 5px;
    }

    h2 {
      font-size: var(--h3);
      font-weight: 400;
      @media (min-width: 768px) {
        font-size: var(--h5);
      }
    }
    
    .holder {
      border-bottom: 2px solid rgba(255, 255, 255, 0.85);
      display:flex;
      align-items:flex-end;
      justify-content:flex-start;
      flex-direction:column;
      
      @media (min-width: 768px) {
        flex-direction:row;
      }

       @media (max-width: 767px) {
          filter: drop-shadow(1px 1px 12px white);
      }

    

      h1,
      .price {
        margin-top: 0;
        font-size: var(--bannerTitle);
      }
    }

    
  }

  .banner__btns {
    display: flex;
    gap: var(--gap);
  }
`
