import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksNaturaStyles } from "./PerksNaturaStyles"
import { MdOutlineClose as Cross } from "react-icons/md"
import Natura from "./Natura"
import Professionalita from '../../../static/professionalita.svg'
import Button from "../Button/Button"
import { Link, navigate } from "gatsby"


const PerksNatura = () => {
  return (
    <PerksNaturaStyles className="section section__padding">
      <StaticImage
        className="perks__image--bg"
        src="../../../static/natura.jpg"
        alt="Perks Module"
        layout="constrained"
        placeholder="blurred"
      />
      <div className="perks__image--overlay"></div>
      <div className="container container__tight">
        <Natura
           title="<i>Ever Green Sabina</i> si impegna nella produzione di servizi che hanno valore per la società, con particolare attenzione per l’ambiente, come mantenere fertili le nostre terre e salvaguardare l'acqua. Nell’uso sostenibile e corretto delle risorse naturali rispettandone gli equilibri essenziali.
           Applichiamo conoscenze antiche apprese dal passato per un futuro responsabile e sostenibile."
           content=""
          classTitle="longtext"
        >
          {/* <StaticImage
            src="../../../static/taglierba.png"
            alt="Perk Image"
            layout="constrained"
            placeholder="tracedSVG"
          />  */}
          {/* <Professionalita></Professionalita> */}
        </Natura>
        
      </div>
    </PerksNaturaStyles>
  )
}

export default PerksNatura
