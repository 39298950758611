import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import BasicTextImageModule from "../components/BasicTextImageModule/BasicTextImageModule"
import PerksModule from "../components/PerksModule/PerksModule"
import PerksServizi from "../components/PerksServizi/PerksServizi"
import PerksNatura from "../components/PerksNatura/PerksNatura"
import Perk from "../components/PerksModule/Perk"
import Servizi from "../components/PerksServizi/Servizi"
import Natura from "../components/PerksNatura/Natura"
import Features from "../components/Features/Features"

import { graphql } from 'gatsby'
//import LatestPosts from "../components/Post/LatestPosts"



const Index = ({data}) => {
  console.log("ECCO I DATI HOMEPAGE",data);
  return (
    <div className="mainLayout">
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Benvenuto da"
          mainTitle="EverGreenSabina"
          subTitle="Giardinaggio, Cura del verde e produzione propria di olio d’oliva al limone Terrae Asprae."
        />
        <BasicTextImageModule
           blocco_sx={data.allContentfulHomePage.nodes[0].bloccoSx}
           blocco_dx={data.allContentfulHomePage.nodes[0].bloccoDx}
         
        />
        <PerksModule dati={data.allContentfulHomePage.nodes[0].bloccoPreventivo}>
          <Perk title="The Title" content="The content" />
        </PerksModule>
        
        <PerksServizi dati={data.allContentfulHomePage.nodes[0].bloccoGenerico} >
          <Servizi/>
        </PerksServizi>
        

        
        <Features
          
          title={data.allContentfulHomePage.nodes[0].bloccoAzienda.heading}
          introduction={data.allContentfulHomePage.nodes[0].bloccoAzienda.contenuto}
        />
        
      </Layout>
    </div>
  )
}

export const query = graphql`
  query HomePageQuery {
    allContentfulHomePage {
      nodes {
        bloccoSx {
          contenuto {
            raw
          }
          heading {
            raw
          }
        }
         bloccoDx {
          contenuto {
            raw
          }
          heading {
            raw
          }
        }
        bloccoPreventivo {
          contenuto {
            raw
          }
          heading {
            raw
          }
        }
        bloccoGenerico {
          contenuto {
            raw
          }
          heading {
            raw
          }
        }
        bloccoAzienda {
          contenuto {
            raw
          }
          heading {
            raw
          }
        }
      }
    }
  }`

export default Index
