import * as React from "react"
import Button from "../Button/Button"
import { BasicTextImageModuleStyles } from "./BasicTextImageModuleStyles"
import { Link } from "gatsby"
import Trattore from '../../../static/trattore.svg'
import { StaticImage } from "gatsby-plugin-image"
import { renderRichText } from 'gatsby-source-contentful/rich-text'


const BasicTextImageModule = ({ title, content, link, linkText,blocco_sx,blocco_dx  }) => {
  return (
    <BasicTextImageModuleStyles className="section">
      <div className="container container__tight">

        <div id="topContent" className="grid">
          <div className="gridImageA">
            <div className="holder-trattore">
              <Trattore></Trattore>
            </div>
          </div>
          <div className="gridImageB">
            <StaticImage
              className="olio_image"
              imgClassName="banner__image--content"
              src="../../../static/terrae_asprae_onwhite_home-removebg.png"
              alt="Banner Image"
              layout="fullWidth"
              placeholder="blurred"
            />
          </div>
          <div className="gridContentA">
            <div className="titolo">{blocco_sx.heading && renderRichText(blocco_sx.heading)}</div>
           
            {blocco_sx.contenuto && renderRichText(blocco_sx.contenuto)}
          

            <div className="holder-btn">
              <Button inverse={true} text={"Scopri i servizi che offiramo"} as={Link} to={"/servizi"} /></div>
          </div>
          <div className="gridContentB">
            <div className="titolo sx">{blocco_dx.heading && renderRichText(blocco_dx.heading)}</div>

               {blocco_dx.contenuto && renderRichText(blocco_dx.contenuto)}
            <div className="holder-btn"><Button inverse={true} text={"Scopri l'olio Terrae Asprae"} as={Link} to={"/olio"} /></div>
          </div>
          <div className="gridContentB_titolo_mobile">
            <div className="titolo sx">{blocco_dx.heading && renderRichText(blocco_dx.heading)}</div>

          </div>
          <div className="gridContentB_p_mobile">
            <div style={{ marginBottom: "60px" }}>
              <p > L’amore per la natura e i diversi percorsi professionali ci aiutano a comprendere come essa vada curata, con rispetto ed esperienza secolare.</p>
              <div className="holder-btn">
                <Button 
                  inverse={true} 
                  text={"Scopri l'olio Terrae Asprae"} 
                  as={Link} 
                  to={"/olio"} />
                </div>

            </div>
          </div>
        </div>
      </div>

    </BasicTextImageModuleStyles>
  )
}

export default BasicTextImageModule
