import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksServiziStyles } from "./PerksServiziStyles"
import { MdOutlineClose as Cross } from "react-icons/md"
import Servizi from "./Servizi"
import Professionalita from '../../../static/professionalita.svg'
import Button from "../Button/Button"
import { Link, navigate } from "gatsby"
//import { useMediaQuery } from 'react-responsive'
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { renderRichText } from 'gatsby-source-contentful/rich-text'


const PerksServizi = ({dati}) => {
  /* const isTabletOrMobile = useMediaQuery({ maxWidth: 996 })
  const isPortrait = useMediaQuery({ orientation: 'portrait' }) */
  const breakpoints = useBreakpoint();
  return (
    <PerksServiziStyles className="section section__padding">

      {/* <StaticImage
        className="perks__image--bg mobile-img"
        src="../../../static/macchinario_mobile.jpg"
        alt="Perks Module"
        layout="constrained"
        placeholder="blurred"
      />

      <StaticImage
        className="perks__image--bg desktop-img"
        src="../../../static/macchinario.jpg"
        alt="Perks Module"
        layout="constrained"
        placeholder="blurred"
      /> */}

      {breakpoints.sm ? (
        <StaticImage
          className="perks__image--bg "
          src="../../../static/macchinario_mobile.jpg"
          alt="Perks Module"
          layout="constrained"
          placeholder="blurred"
        />
      ) : (
          <StaticImage
            className="perks__image--bg"
            src="../../../static/macchinario.jpg"
            alt="Perks Module"
            layout="constrained"
            placeholder="blurred"
          />
        )
      }


      <div className="perks__image--overlay"></div>
      <div className="container container__tight">
        <Servizi
          title=""
          content=""
          classTitle="longtext"
        >

        </Servizi>

        <Servizi
          inverse={true}
          classTitle="longtext"
          title={dati.heading}
          content={dati.contenuto}
        >

          {/* <StaticImage
            src="../../../static/logos/contentful-logo.svg"
            alt="Perk Image"
            layout="constrained"
            placeholder="tracedSVG"
          /> */}
         {/*  <Button
            className="btn"
            text="Clicca qui"
            as={Link}
            to="/contatti"
          /> */}
        </Servizi>
      </div>
    </PerksServiziStyles>
  )
}

export default PerksServizi
