import * as React from "react"
import { ServiziStyles } from "./PerksServiziStyles"
import { renderRichText } from 'gatsby-source-contentful/rich-text'


const Servizi = ({ children, title, content  ,classTitle ,inverse}) => {
  return (
    <ServiziStyles className={classTitle||""}>
      {!inverse && children}
      <h3>
        {title && renderRichText(title)}
      </h3>
      {content && renderRichText(content)}
      {inverse && children}
    </ServiziStyles>
  )
}

export default Servizi
