import * as React from "react"
import { Link, navigate } from "gatsby"
import { BannerModuleStyles } from "./BannerModuleStyles"
import { StaticImage } from "gatsby-plugin-image"

import Button from "../Button/Button"
import LogoSvg from '../../../static/logo_egs.svg'
import LogoSvgNoText from '../../../static/evergreen_no_text.svg'

const BannerModule = ({ children, title, mainTitle, subTitle, price, enquire}) => {
 
  function scrollToArea() {
    navigate("#topContent")
  }

  return (
    <>
      <BannerModuleStyles>
        {children ? (
          children
        ) :  (
              <StaticImage
                className="banner__image"
                imgClassName="banner__image--content"
                src="../../../static/evergreensabina.jpg"
                alt="Banner Image"
                layout="fullWidth"
                placeholder="blurred"
              />
            )}
        {/* <div class="sideColor"></div>
        <div class="sideColorPatch"></div> */}
        <div className="container">
          <div className="banner__content" id="banner__content_id">
            <div className="sx">
              {title && mainTitle && (
                <div className="holder">

                  {/*  {mainTitle} */}
                  {/* <span style={{ color: "var(--primary)" }}>.</span> */}
                  <div className="logo_banner">
                    <span className="welcomeText"> {title} </span>
                    <LogoSvgNoText></LogoSvgNoText>
                  </div>
                  <h1>
                    Curiamo il verde in guanti bianchi
                    <span style={{ color: "var(--primary)" }}>.</span>
                  </h1>

                </div>

              )}
              {subTitle && <h2>{subTitle}</h2>}
              {price && (
                <h2 className="price">
                  £{price}
                  <span style={{ color: "var(--primary)" }}>.</span>
                </h2>
              )}
              <div className="banner__btns">
                {enquire && (
                  <Button
                    className="btn"
                    text="Enquire Now"
                    as={Link}
                    to="/#topContent"
                  />
                )}
                <Button
                    className="btn"
                    text="Scopri di più"
                    as={Link}
                    to="/#topContent"
                  />
               {/*  <Button onClick={scrollToArea()} text="Scopri di più" /> */}
              </div>

            </div>
            {/* <div className="dx">
              <LogoSvg className="logo__imagee"
                imgClassName="logo__image--contentt"></LogoSvg>
              
              <span className="sub-title">Il tuo specialista di fiducia nel settore agricolo</span>
            </div> */}
          </div>

        </div>
        <div className="gradient"></div>
      </BannerModuleStyles>
      <span id="topContent"></span>
    </>
  )
}

export default BannerModule
